import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Blockart extends Component {
    render() {
        return (
            <Box_home>
            <Container>
                <H3tiel> </H3tiel>
                <div className="gkl40">
                  <img src="./mak.png" alt="" />
                </div>
            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    padding: 0 0 35px 0;
    min-height:90vh;
    background:url('./d88.png');
    background-size: cover;
    background-position: center;
    z-index:1;
    background-repeat: no-repeat;

    .t0ch{
      text-align:center;
      color: #A0AEE5;
    }
    .gkl40{
      min-height: 80vh;
      display: flex;
      align-items: flex-end;
    }

`;


const Grhb3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap:30px;
  margin-bottom:25px;
  @media (max-width: 770px) {

  }

  .ct0{
    width:31%;
    text-align:center;
    background: rgb(182 186 191 / 12%);
    border-radius: 15px;
    padding: 20px;
    border: solid 1px #152152;
    @media (max-width: 770px) {
    width:100%
    }

    .i0btw{
      margin-bottom:10px;
    }

    p{
      color: #A0AEE5;
      text-align: justify;

    }
    h4{
      color: #ECF0FF;
      font-size:24px;
    }

  }
`




const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #ffffff;
  text-align:center;
  font-family: "Kalam", cursive;
`;

const BUys = styled.div`
  background: linear-gradient(178.25deg, #03CFFF 17.89%, #0052FF 98.52%);
  max-width:235px;
  width:100%;
  margin:0 auto;
  border-radius:20px;
  text-align:center;

  a{
    color:#fff;
    font-size:26px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 55px;
  }
`;



export default Blockart;
