import React, { Component } from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class Map extends Component {
    render() {
        return (
            <Box_home>
            <Container>
                <div className="bottom-f7h">
                  <a href="https://pump.fun/38z85JrWPcJsacTB5yxjQiWZ7vK8CVcdvvEaWRHvpump"><img src="./v7s.svg" alt="" /></a>
                  <a target="_blank" href="https://x.com/MenWearDog"><img className="mg50" src="./x.svg" alt="" /></a>
                  <a target="_blank" href="https://t.me/MenDogPortal"><img className="mg50" src="./tg.svg" alt="" /></a>
                </div>
            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 5px 0 0 0;

    .bottom-f7h{
      display:flex;
      align-items: center;
      gap:15px;
      justify-content: center;
      p{
        margin-bottom:0;
        font-size:32px;
        font-family: "Kalam", cursive;
        color:#ffffff;
      }
      .mg50{
        max-width:50px;
      }
    }

`;




export default Map;
