import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

class Block5 extends Component {
    render() {
        return (
            <Box_home>
            <Container>
            {/* <H3tiel> <img src="./pis.png" alt="" /><br /> </H3tiel> */}
            <GroupDs></GroupDs>
            <div className="gkl40">
              <img src="./hokl.png" alt="" />
            </div>
            <div className="gkl41">
              <img src="./pus.png" alt="" />
            </div>
            <img src="h21317355.png" alt="" />
            <img src="m13213335.png" alt="" />
            <img src="skk.png" alt="" />
            <p></p>
            </Container>
          </Box_home>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 55px 0;
    min-height:90vh;
    background:url('./h8t.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img{
      margin-bottom:2rem;
    }

    @media (max-width: 770px) {
      padding:25px 0;
      min-height:50vh;
    }

    .gkl40{
      text-align:center;
      @media (max-width: 770px) {
        height: 50vh;
        display: flex;
        align-items: center;
      }

    }

    .gkl41{
      text-align:center;
      img{
        max-width:100%;
        margin:auto;

        @media (max-width: 770px) {
          max-width:100%;
        }

      }

      @media (max-width: 770px) {
      padding:10px;
      }
    }
`;


const H3tiel = styled.h3`
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 2rem;
  color: #ffffff;
  text-align:center;

  img{
    width:40%;
  }
`;



const GroupDs = styled.div`
  display:flex;

  img{
    width:40%;
  }
`;



export default Block5;
